<template>
   <!-- TODO: 
   - adjust photo and info
   1- @vue/eslint-config-prettier@5.0.0 requires a peer of prettier@>= 1.13.0 but none is installed. You must install peer dependencies yourself.
   2- sass-loader@8.0.0 requires a peer of node-sass@^4.0.0 but none is installed. You must install peer dependencies yourself.
   3- sass-loader@8.0.0 requires a peer of fibers@>= 3.1.0 but none is installed. You must install peer dependencies yourself.
   -->
   <!-- HISTORY:
   - V230224.1: Modified formatTimestamp() to display dates in the local timezone.
   - 10/22/21(B1.1): Added scoresTooltip to the line chart.
   - 10/19/21(B1.0): Added LineChart for scores to the top of events timeline. + Removed scoped from <style> and added .v-expansion-panel-content__wrap.
   - 10/20/20(B0.7): Added extra logic to parse non-ISO dates with 'yyyyMMdd' format.
   - 10/16/20(B0.6): Handled "Invalid time value" error for standard date fields.
   - 04/22/20(B0.5): Added loading and removed overlay.
   - 03/30/20(B0.4): Adjusted the font size/weight of table in the timeline..
   - 03/27/20(B0.3): Put all related input form/response together in one timeline.
   -->
   <v-container fluid>
      <v-icon dark
         :color="iconColor"
         @click="iconClicked"
      >{{iconName}}</v-icon>

      <v-dialog v-model="dialog" persistent no-click-animation max-width="1080px" scrollable>
         <v-card>
            <v-card-text class="pb-0" style="height: 95vh">
               <v-container>

                  <v-row>
                     <v-col cols="12" xs="12" sm="12" md="7" lg="7"><!--Photo and basic info-->
                        <v-card :loading="cfLoading">
                           <v-row>
                              <v-spacer></v-spacer>
                              <v-col xs="12" sm="12" md="5" lg="5">
                                 <v-avatar class="mt-2 ml-5" size="160">
                                    <v-img :src="cf.photourl"></v-img>
                                 </v-avatar>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col xs="12" sm="12" md="7" lg="7" :class="$vuetify.breakpoint.smAndDown?'ml-5':'ml-0'">
                                 <v-row dense style="flex-wrap: nowrap;">
                                    <v-col :cols="headerCols" :class="headerClass">Name:</v-col>
                                    <v-col :cols="12 - headerCols">{{name}}</v-col>
                                 </v-row>
                                 <v-row dense>
                                    <v-col :cols="headerCols" :class="headerClass">PURL:</v-col>
                                    <v-col :cols="12 - headerCols">{{cf.purl}}</v-col>
                                 </v-row>
                                 <v-row dense>
                                    <v-col :cols="headerCols" :class="headerClass">Email:</v-col>
                                    <v-col :cols="12 - headerCols">{{cf.email}}</v-col>
                                 </v-row>
                                 <v-row dense>
                                    <v-col :cols="headerCols" :class="headerClass">Mobile:</v-col>
                                    <v-col :cols="12 - headerCols">{{cf.mobile}}</v-col>
                                 </v-row>
                                 <v-row dense>
                                    <v-col :cols="headerCols" :class="headerClass">Phone:</v-col>
                                    <v-col :cols="12 - headerCols">{{phone}}</v-col>
                                 </v-row>
                                 <v-row dense>
                                    <v-col :cols="headerCols" :class="headerClass">Address:</v-col>
                                    <v-col :cols="12 - headerCols">
                                       <span v-if="cf.address1" class="my-0 py-0">
                                          {{cf.address1}}<br/>
                                       </span>
                                       <span v-if="cf.address2" class="my-0 py-0">
                                          {{cf.address2}}<br/>
                                       </span>
                                       <span v-if="city_state_zip" class="my-0 py-0">
                                          {{city_state_zip}}<br/>
                                       </span>
                                       <span v-if="cf.country" class="my-0 py-0">
                                          {{cf.country}}<br/>
                                       </span>
                                       <span v-for="(item,i) in [cf.address1,cf.address2,city_state_zip,cf.country]" :key="i" class="my-0 py-0">
                                          <br v-if="!item">
                                       </span>
                                    </v-col>
                                 </v-row>
                              </v-col>
                           </v-row>
                        </v-card>
                     </v-col>

                     <v-col cols="12" xs="12" sm="12" md="5" lg="5"><!--map-->
                     <v-card :loading="cfLoading || gmapLoading">
                        <v-card class="py-5 px-5">
                           <GmapMap
                              map-type-id="terrain"
                              style="width: 370; height: 230px"
                              :center="{lat, lng}"
                              :zoom="13"
                           >
                              <GmapMarker
                                 :position="google && new google.maps.LatLng(lat, lng)"
                                 :clickable="true"
                                 :draggable="true"
                                 :title="formattedAddress"
                              />
                           </GmapMap>
                        </v-card>
                        </v-card>
                     </v-col>
                  </v-row>

                  <v-row>
                     <v-col xs="12" sm="12" md="5" lg="5"><!-- contact fields -->
                        <v-expansion-panels multiple focusable v-model="panelsL">
                           <v-expansion-panel readonly>
                              <v-card :loading="cfLoading">
                                 <v-expansion-panel-header hide-actions>
                                    <strong>Contact Fields</strong>
                                    <v-spacer></v-spacer>
                                    {{contactFieldsTooltip}}
                                    <!-- <span class="font-weight-black mx-0">Contact Fields</span>
                                    <span class="caption pl-1">({{contactFieldsTooltip}})</span> -->
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content>

                                    <v-row v-if="fieldFormat=='2-col'">
                                       <v-col v-for="i in standardProps.length" :key="i" :cols="12 / numOfCols">
                                          <v-row v-for="p in standardProps[i-1]" :key="p" dense>
                                             <v-col cols="6" :class="headerClass">{{getDesc(p)}}:</v-col>
                                             <v-col cols="6">{{cf.standard[p]}}</v-col>
                                          </v-row>
                                       </v-col>
                                    </v-row>
                                    <v-row v-else>
                                       <v-col v-for="i in standardProps.length" :key="i" :cols="12 / numOfCols">
                                          <v-row v-for="p in standardProps[i-1]" :key="p" dense>
                                             <v-col cols="12">
                                                <span :class="headerClass">{{getDesc(p)}}:&nbsp;</span>
                                                <span>{{cf.standard[p]}}</span>
                                             </v-col>
                                          </v-row>
                                       </v-col>
                                    </v-row>

                                    <v-divider class="my-0 py-0" v-if="showDivider"></v-divider>

                                    <v-row v-if="fieldFormat=='2-col'">
                                       <v-col v-for="i in customProps.length" :key="i" :cols="12 / numOfCols">
                                          <v-row v-for="p in customProps[i-1]" :key="p" dense>
                                             <v-col cols="6" :class="headerClass">{{p}}:</v-col>
                                             <v-col cols="6">{{cf.custom[p]}}</v-col>
                                          </v-row>
                                       </v-col>
                                    </v-row>
                                    <v-row v-else>
                                       <v-col v-for="i in customProps.length" :key="i" :cols="12 / numOfCols">
                                          <v-row v-for="p in customProps[i-1]" :key="p" dense>
                                             <v-col cols="12">
                                                <span :class="headerClass">{{p}}:&nbsp;</span>
                                                <span>{{cf.custom[p]}}</span>
                                             </v-col>
                                          </v-row>
                                       </v-col>
                                    </v-row>

                                 </v-expansion-panel-content>
                              </v-card>

                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-col>

                     <v-col xs="12" sm="12" md="7" lg="7"><!-- scores & events -->
                        <v-expansion-panels v-if="contactData.scores && contactData.scores.length > 1"
                           multiple focusable
                           class="pb-3"
                           v-model="panelsRT"
                        >
                           <v-expansion-panel readonly>
                              <v-card :loading="cfLoading">
                                 <v-expansion-panel-header hide-actions>
                                    <strong>Scores</strong>
                                    <v-spacer></v-spacer>
                                    {{scoresTooltip}}
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content>
                                    <GChart
                                       type="LineChart"
                                       :data="contactData.scores"
                                       :options="chartOptions"
                                       :settings="chartSettings"
                                    />
                                 </v-expansion-panel-content>
                              </v-card>
                           </v-expansion-panel>
                        </v-expansion-panels>

                        <v-expansion-panels multiple focusable v-model="panelsRB">
                           <v-expansion-panel readonly>
                              <v-card :loading="cfLoading">
                                 <v-expansion-panel-header hide-actions>
                                    <strong>Events</strong>
                                    <v-spacer></v-spacer>
                                    {{eventsTooltip}}
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content>
                                    <v-timeline dark dense color="blue">
                                       <v-timeline-item fill-dot
                                          v-for="(e, i) in cf.events" :key="i"                                        
                                          :color="getColor(e.servicetype_id)"
                                       >
                                          <template v-slot:icon>
                                             <v-avatar size="32">
                                                <img :src="getIcon(e.servicetype_id)">
                                             </v-avatar>
                                          </template>
                                          <!-- <template v-slot:opposite>
                                             <span>{{formatTimestamp(e.eventtimestamp).date}}</span><br>
                                             <span>{{formatTimestamp(e.eventtimestamp).time}}</span>
                                          </template> -->
                                          <v-card dark :color="getColor(e.servicetype_id)" class="pb-0">
                                             <v-card-title class="pt-2" style="height:46px;">
                                                <span class="title">{{e.servicetype_name.split("::")[1]}}</span>
                                                <v-spacer></v-spacer>
                                                <span class="body-2">{{formatTimestamp(e.eventtimestamp).dateTime}}</span><br>
                                             </v-card-title>

                                             <v-card-text class="white text--primary pt-2 pb-1">
                                                <p>{{formatContent(e.event_name)}}</p>
                                                <p v-for="(prop, j) in getEventProps(e.eventDetails[0])" :key="j" class="my-0">
                                                   <span :class="headerClass">{{getDesc(prop)}}: </span>
                                                   <span>{{e.eventDetails[0][prop]}}</span>
                                                </p>
                                                <v-data-table v-if="e.event_name.indexOf('30505')===0"
                                                   dense light
                                                   class="elevation-1 mt-2 mb-2 font-weight-light caption"
                                                   item-key="forminput"
                                                   item-value="formresponse"
                                                   :headers="[
                                                      { text: getDesc('forminput'), value: 'forminput', divider: true, class:'font-weight-bold body-2 font-italic' },
                                                      { text: getDesc('formresponse'), value: 'formresponse', divider: true, class:'font-weight-bold body-2 font-italic' }
                                                   ]"
                                                   :items="e.eventDetails"
                                                   :hide-default-footer="e.eventDetails.length<11"
                                                >
                                                   <template v-slot:[`header.name`]="{ header }">
                                                      {{ header.text.toUpperCase() }}
                                                   </template>
                                                </v-data-table>
                                             </v-card-text>
                                          </v-card>
                                       </v-timeline-item>
                                    </v-timeline>
                                 </v-expansion-panel-content>
                              </v-card>
                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-col>
                  </v-row>

               </v-container>
            </v-card-text>

            <v-card-actions class="mr-8">
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="closeDialog"
               >Close</v-btn>
            </v-card-actions>
         </v-card>

         <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
         </v-overlay> -->

      </v-dialog>
   </v-container>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { format, parse, parseISO } from "date-fns";
import { GChart } from 'vue-google-charts';

class ContactFields {
   constructor(photoUrl) {
      this.firstname = '';
      this.lastname = '';
      this.middlename = '';
      this.purl = '';
      this.email = '';
      this.mobile = '';
      this.phone = '';
      this.extension = '';
      this.address1 = '';
      this.address2 = '';
      this.city = '';
      this.state = '';
      this.zip = '';
      this.zipplus4 = '';
      this.country = '';
      this.photourl = photoUrl,
      this.gender = '';
      this.standard = {},
      this.custom = {},
      this.events = []
   }
}

class Event {
   constructor() {
      this.servicetype_id = '';
      this.servicetype_name = '';
      this.event_name = '';
      this.eventtimestamp = '';
      // this.eventDetails = new EventDetails();
      this.eventDetails = [];
   }
}

class EventDetails {
   constructor() {
      this.program = '';
      this.inbound = '';
      this.inbounddetail = '';
      this.forminput = '';
      this.formresponse = '';
      this.outbound = '';
      this.outboundschedule = '';
      this.goal = '';
      this.score = '';
      this.duration = '';
      this.linkname = '';
      this.eventoption = '';
   }
}

const GEOCODE_URL = "https://maps.googleapis.com/maps/api/geocode/json?address=";
const PHOTO_URL_M = require('../assets/dummy-photo-male.jpeg');
const PHOTO_URL_F = require('../assets/dummy-photo-female.jpeg');
const PHOTO_URL_U = require('../assets/dummy-photo-unknown.jpeg');
// const PHOTO_URL_M = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTxwW_UA2nE2LXcL5t_iYkTPj3vkEKzxFh4IzaecpjeYs39GzCP';
// const PHOTO_URL_F = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR_Cu-kaNa4C_NMJSTbCLRtn0EM7KqtQVwG3irRxwGBFC4cPgq3';
// const PHOTO_URL_U = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQJcJbnAJdQkd8XSqtS_-bF4cmNmgO6bq8gmpD7IEA37fszMji8';
let DEBUG;

function _log(msg, log) {
  if (DEBUG || log) console.log(`BtContactProfile V230224.1 says => ${msg}`);
}

export default {
   name: "BtContactProfile",

   components: { GChart },

   props: {
      contactData: {
         type: Object,
         default: () => {}
      },
      debug: {
         type: Boolean,
         default: false
      },
      iconName: {
         type: String,
         default: "person"
      },
      iconColor: {
         type: String,
         default: "grey darken-1"
      },
      nameFormat: {
         type: String,
         default: 'L, F M'
      },
      fieldFormat: {
         type: String,
         default: '1-col',
         validator: value => { return ['1-col', '2-col'].indexOf(value.toLowerCase()) !== -1 }
      }
   },

   data() {
      return {
         dialog: false,
         panelsL: [0],
         panelsRT: [0],
         panelsRB: [0],
         cf: new ContactFields(PHOTO_URL_U),
         headerClass: 'font-weight-bold flex-grow-1 flex-shrink-0',
         standardProps: [],
         customProps: [],
         // lat: 33.682610,
         // lng: -117.840400,
         lat: 0,
         lng: 0,
         formattedAddress: '',
         // overlay: true,
         contactFieldsTooltip: '',
         eventsTooltip: '',
         cfLoading: true,
         gmapLoading: true,
         scoresTooltip: '',
         chartOptions: {
            "height":"230",//250
            "width":"100%",//370,100%
            "legend":{"position":"none"},
            "chartArea":{"left":"40","right":"20"},
            "hAxis":{"format":"MMM dd","gridlines":{"count":5}}
         },
         chartSettings: {
            packages: ['corechart', 'table', 'map', 'geochart'],
            mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY
         },
      };
   },

   computed: {
      name() {
         const format = this.nameFormat.toUpperCase();
         return (format.replace('F', this.cf.firstname).replace('L', this.cf.lastname).replace('M', this.cf.middlename).trim());
      },
      phone() {
         return this.cf.phone.trim() 
            + (this.cf.phone && this.cf.extension ? ' (X' + this.cf.extension  + ')' : '');
      },
      city_state_zip() {
         return this.cf.city
            + (this.cf.city && this.cf.state ? ', ' : '')
            + this.cf.state
            + (this.cf.city || this.cf.state ? ' ' : '')
            + this.cf.zip
            + (this.cf.zip && this.cf.zipplus4 ? '-' : '')
            + this.cf.zipplus4
         ;
      },
      headerCols() {
         return 3;
      },
      numOfCols() {
         return 1;
      },
      showDivider() {
         return this.standardProps.length > 0 && this.standardProps[0].length > 0 
            && this.customProps.length > 0 && this.customProps[0].length > 0;
      },
      google: gmapApi
   },

   watch: {
      contactData() {
         this.initDialog();
      }
   },

   methods: {
      iconClicked() {
         this.dialog = true;
         this.initDialog();
      },

      initDialog() {
         _log(`in initDialog(): contactData=${JSON.stringify(this.contactData)}`, true);

         // this.overlay = true;
         this.gmapLoading = true;
         this.cfLoading = true;

         if (this.contactData === null || Object.keys(this.contactData).length === 0) {
            return;
         }

         var val;
         this.cf = new ContactFields(PHOTO_URL_U);

         for (var prop in this.contactData.standardFields) {
            if (this.contactData.standardFields.hasOwnProperty(prop)) {
               val = this.contactData.standardFields[prop];
               switch (prop) {
                  case 'firstname':
                  case 'lastname':
                  case 'middlename':
                  case 'purl':
                  case 'email':
                  case 'mobile':
                  case 'phone':
                  case 'extension':
                  case 'address1':
                  case 'address2':
                  case 'city':
                  case 'state':
                  case 'zip':
                  case 'zipplus4':
                  case 'country':
                  case 'photourl':
                  case 'gender':
                     this.cf[prop] = val;
                     break;
                  case 'accountid':
                  case 'company':
                  case 'deliverypointindicator':
                  case 'facebookaccount':
                  case 'fax':
                  case 'linkedinaccount':
                  case 'password':
                  case 'prefix':
                  case 'suffix':
                  case 'title':
                  case 'twitteraccount':
                  case 'website':
                  case 'isseed':
                     if (val)
                        this.cf.standard[prop] = val;
                     break;
                  case 'anniversary':   //2018-03-05T21:31:39.490Z
                  case 'birthdate':    //2018-03-05T21:31:39.490Z
                  case 'creationdate': //2018-03-05T21:31:39.490Z
                     if (val) {
                        try {
                           this.cf.standard[prop] = format(parseISO(val.toUpperCase()), 'M/d/yyyy h:mm:ss a');
                           _log(`in initDialog(): ${prop}=${val} => ${this.cf.standard[prop]}`);
                        } catch (error) {
                           try {
                              this.cf.standard[prop] = format(new Date(val.substr(0, 4), val.substr(4, 2), val.substr(6, 2)), 'M/d/yyyy');
                              this.cf.standard[prop] = format(parse(val.substr(0, 8), 'yyyyMMdd', new Date()), 'M/d/yyyy');
                              _log(`in initDialog(): ${prop}=${val} => ${this.cf.standard[prop]}`);
                           } catch (error) {
                              this.cf.standard[prop] = val;
                              _log(`in initDialog(): ${prop}=${val} => ${error}`);
                           }
                        }
                     }
                     break;
                  default:
                     //ignore non-standard fields
                     break;
               }
            }
         }

         this.getLatLng();

         if (this.nameFormat.toUpperCase().indexOf('M') === -1 && this.cf.middlename)
            this.cf.standard['middlename'] = this.cf.middlename;

         if (this.cf.gender)
            this.cf.standard['gender'] = this.cf.gender;

         if (!this.cf.photourl || this.cf.photourl === PHOTO_URL_U)
         {
            var gender = this.cf.gender ? this.cf.gender.substr(0, 1).toUpperCase() : '';
            if (gender === 'M')
               this.cf.photourl = PHOTO_URL_M;
            else if (gender === 'F')
               this.cf.photourl = PHOTO_URL_F;
            else
               this.cf.photourl = PHOTO_URL_U;
         }

         for (prop in this.contactData.customFields) {
            if (this.contactData.customFields.hasOwnProperty(prop)) {
               val = this.contactData.customFields[prop];
               _log(`in customLoop: prop=${prop}, val=${val}`);
               if (val) this.cf.custom[prop] = val;
            }
         }

         this.standardProps = this.getProps(this.cf.standard);
         this.customProps = this.getProps(this.cf.custom);

         let sTooltip = '';
         let cTooltip = '';
         if (this.standardProps[0].length > 0)
            sTooltip = `${this.standardProps[0].length} standard`;
         if (this.customProps[0].length > 0)
            cTooltip = `${this.customProps[0].length} custom`;
         this.contactFieldsTooltip = this.joinStrings(sTooltip, cTooltip);

         let inbound = 0;
         let outbound = 0;

         this.contactData.events.forEach(event => {
            if (event['servicetype_id'] === '305') inbound++;
            else outbound++;

            var e = new Event();
            var eDetails = new EventDetails();
            for (prop in event) {
               if (event.hasOwnProperty(prop)) {
                  val = event[prop];
                  switch (prop) {
                     case 'servicetype_id':
                     case 'servicetype_name':
                     case 'event_name':
                     case 'eventtimestamp':
                        e[prop] = val;
                        break;
                     case 'goal_name':
                     case 'program_name':
                     case 'inbound_name':
                     case 'inbounddetail_name':
                     case 'forminput_name':
                     case 'outbound_name':
                     case 'outboundschedule_name':
                        if (val)
                           eDetails[prop.split('_')[0]] = this.formatContent(val);
                        break;
                     case 'formresponse':
                     case 'score':
                     case 'duration':
                     case 'linkname':
                     case 'eventoption':
                        if (val)
                           eDetails[prop] = val;
                        break;
                     default:
                        //ignore unwanted fields
                        break;
                  }
               }
            }

            let eventInd = -1;
            if (eDetails.inbounddetail)
               eventInd = this.cf.events.findIndex(e => e.eventDetails[0].inbounddetail === eDetails.inbounddetail);
            
            if (eventInd === -1) {
               e.eventDetails.push(eDetails);
               this.cf.events.push(e);
            } else
               this.cf.events[eventInd].eventDetails.push(eDetails);
         });

         let iTooltip = '';
         let oTooltip = '';
         if (inbound > 0) iTooltip = `${inbound} inbound`;
         if (outbound > 0) oTooltip = `${outbound} outbound`;
         this.eventsTooltip = this.joinStrings(iTooltip, oTooltip);

         if (this.contactData.scores && this.contactData.scores.length > 1) {
            let total = 0;
            for (let index = 1; index < this.contactData.scores.length; index++) {
               total += this.contactData.scores[index][1];
            }
            this.scoresTooltip = 'Total: ' + new Intl.NumberFormat('en-US', { }).format(total);
         }

         _log(`in initDialog(): cf=${JSON.stringify(this.cf)}`);
         this.cfLoading = false;
         // this.overlay = false;
      },

      joinStrings(s1, s2) {
         let joint = s1;
         if (s2) {
            if (s1) joint += ', ' + s2;
            else joint = s2;
         }

         return joint;
      },

      getProps(fields) {
         var arrProps = [];
         for (var i=0; i<this.numOfCols; i++)
            arrProps[i] = [];

         var seq = 0;

         for (var prop in fields) {
            if (fields.hasOwnProperty(prop)) {
               arrProps[(seq + this.numOfCols) % this.numOfCols].push(prop);
               seq++;
            }
         }
      
         _log('in getProps(): arrProps=' + JSON.stringify(arrProps));
         return arrProps;
      },

      getDesc(prop) {
         switch (prop) {
            case 'accountid':
               return 'Account ID';
            case 'birthdate':
               return 'Birth Date';
            case 'deliverypointindicator':
               return 'Delivery Point Indicator';
            case 'facebookaccount':
               return 'Facebook Account';
            case 'linkedinaccount':
               return 'LinkedIn Account';
            case 'twitteraccount':
               return 'Twitter Account';
            case 'isseed':
               return 'Is Seed';
            case 'creationdate':
               return 'Creation Date';
            case 'inbounddetail':
               return 'HTML Page';
            case 'forminput':
               return 'Form Input';
            case 'outboundschedule':
               return 'Outbound Schedule';
            case 'formresponse':
               return 'Form Response';
            case 'linkname':
               return 'Link Name';
            case 'eventoption':
               return 'Event Option';
            default:
               var firstLetter = prop.substring(0, 1);
               return firstLetter.toUpperCase() + prop.substring(1);
         }
      },

      getColor(id) {
         switch (id) {
            case '201':    //Email
               return 'indigo';
            case '205':    //DirectMail
               return 'green lighten-1';
            case '305':    //Microsite
               return 'purple darken-1';
            case '2009':   //HP SmartStream
               return 'cyan lighten-2';
            case '2016':   //Text2Voice
               return 'gray darken-2';
            case '2018':   //SMS (Text)
               return 'pink darken-2';
            case '2019':   //Cloud Connect
               return 'brown lighten-1';
            case '2023':   //Data Processor
               return 'blue-grey lighten-1';
            case '2024':   //Mail Merge
               return 'green darken-4';
            case '2026':   //Zapier
               return 'blue lighten-2';
            case '2028':   //PurlDP
               return 'indigo lighten-2';
            case '2030':   //SendGrid
               return 'orange lighten-1';
            case '3000':   //RDE
               return 'green accent-2';
            default:
               return 'red darken-4';
         }
      },

      getIcon(id) {
         if (id === '2030')
            return 'https://s3-us-west-2.amazonaws.com/behzad-mindfire-bucket/sgLogo.png';
         else
            return 'https://mindfirestudio.s3.us-west-1.amazonaws.com/App/assets/' + id + '.png';
      },

      getEventProps (eventDetails) {
         var arrProps = [];

         for (var prop in eventDetails) {
            if (prop != 'forminput' && prop != 'formresponse' && eventDetails.hasOwnProperty(prop) && eventDetails[prop]) {
               arrProps.push(prop);
            }
         }
      
         _log('in getEventProps(): arrProps=' + JSON.stringify(arrProps));
         return arrProps;
      },

      formatContent(content) {
         var parts = content.split("::");
         if (parts.length === 2) return `${parts[1]} (${parts[0]})`;
         else return content;
      },
   
      formatTimestamp_V230224(timestamp) {
         var parts = format(parseISO(timestamp), 'M/d/yyyy h:mm:ss a').split(' ');
         var dt = { date: parts[0], time: parts[1] + ' ' + parts[2] };
         dt.dateTime = dt.date + ' ' + dt.time;
         return dt;
      },

      getTimezoneOffset(timeZone, date = new Date()) {
         const tz = date.toLocaleString("en", {timeZone, timeStyle: "long"}).split(" ").slice(-1)[0];
         const dateString = date.toString();
         const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
         // alert(`tz=${tz}\ndateString=${dateString}\noffset=${offset}`);

         // return UTC offset in minutes
         return offset / 60000;
      },

      formatTimestamp(timestamp) {
         const tzOffset = this.getTimezoneOffset("America/Los_Angeles", new Date(timestamp));
         const utc = new Date(new Date(timestamp).toUTCString());
         utc.setMinutes(utc.getMinutes() + Math.abs(tzOffset));
         // alert(`tzOffset=${tzOffset}\nutc=${utc}\n${format(utc, 'M/d/yyyy h:mm:ss a')}`);

         // var parts = format(parseISO(timestamp), 'M/d/yyyy h:mm:ss a').split(' ');
         var parts = format(utc, 'M/d/yyyy h:mm:ss a').split(' ');
         var dt = { date: parts[0], time: parts[1] + ' ' + parts[2] };
         dt.dateTime = dt.date + ' ' + dt.time;
         return dt;
      },

      async getLatLng() {
         this.lat = 0;
         this.lng = 0;
         this.formattedAddress = '';

         try {
            const address = `${this.cf.address1}, ${this.cf.address2}, ${this.cf.city}, ${this.cf.country}`;
            _log(`in getLatLng(): address=${address}`);

            let components = '';
            if (this.cf.zip)
               components = '&components=' + encodeURIComponent('postal_code:' + this.cf.zip);

            let response = await fetch(`${GEOCODE_URL}${encodeURIComponent(address)}${components}&key=${process.env.VUE_APP_GOOGLE_API_KEY}`);
            response = await response.json();
            _log(`in getLatLng(): response=${JSON.stringify(response)}`);

            if (response.results.length > 0) {
               const r = response.results[0];
               this.lat = r.geometry.location.lat;
               this.lng = r.geometry.location.lng;
               this.formattedAddress = r.formatted_address;
            } else if (response.status === 'ZERO_RESULTS')
               _log('in getLatLng(): No results could be found!');
            else
               _log(`ERROR in getLatLng(): status=${response.status}, error_message=${response.error_message}`, true);
         } catch (ex) {
            _log(`EXCEPTION in getLatLng(): ${ex}`, true);
         } finally {
            this.gmapLoading = false;
         }
      },

      closeDialog() {
         this.dialog = false;
      }
   },

   mounted() {
      DEBUG = this.debug;
   }
};
</script>

<style>
   .v-card__title.title {
      padding-top: 8px; padding-bottom: 8px;
   }
   .v-toolbar__content {
      padding: 0 0;
   }
   .theme--dark.v-timeline:before {
    background: grey;
   }
   .v-expansion-panel-header {
      min-height: 54px;
      /* font-weight: bold; */
   }
   .line-break span::before {
      content: '\A'
   }
   .v-expansion-panel-content__wrap {
      padding-bottom: 0px;
   }
</style>
